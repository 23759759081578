<template>
  <div class="videoModel">
    <el-dialog
      v-model="videoVisible"
      title="视频播放"
      width="30%"
      @close="handleClose"
    >
      <div>
        <video style="margin: 0 auto" width="800" height="500" controls autoplay>
          <source :src="videoPath" type="video/mp4" />
          你的浏览器不支持视频播放
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, defineComponent, onMounted, ref, inject } from 'vue'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { getAction, postAction } from '../api/index'

export default defineComponent({
  name: 'List',
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const videoVisible = ref(false)
    const handleClose = inject('closeVideo')
    const videoPath = ref(null)
    onMounted(() => {
      console.log(props)
      videoVisible.value = props.visible
      videoPath.value = props.path
    })

    return {
      videoVisible,
      handleClose,
      videoPath
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-dialog{
  width: 850px !important;
  margin-top: 100px !important;
}
/deep/ .el-dialog__body{
  padding-top: 0px!important;
}
</style>
