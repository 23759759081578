<template>
  <div class="header">
    <div  class="bg"  >
      <div class="bgImg" id="bg"/>
      <div class="top">
        <div class="top-left">
          <div class="time">{{ timer }}&nbsp;&nbsp;{{ week }}</div>

        </div>
        <div class="top-right">
          <div v-if="!userInfo" class="login" @click="login">登录</div>
          <div v-else class="user" style="margin-right: 20px">
            <div style="color: #FFFFFF;font-size: 14px">{{ userInfo.realname }}，
              <el-popconfirm title="确定退出登录?" confirmButtonText="确定" cancelButtonText="取消" @confirm="logOut">
                <template #reference>
                  <a style="color: #FFFFFF;cursor: pointer">退出</a>
                </template>
              </el-popconfirm>
            </div>
          </div>
          <!--          <div v-if="getUserInfo" class="right-text">网站导航</div>-->
        </div>
      </div>
        <img style="margin: 20px auto;z-index: 1" src="../assets/n_logo.png">
      <div class="search-box">
        <i class="search-icon" @click="search"></i>
        <input class="my_input" v-model="keyWord" type="text" @keyup.enter="search" placeholder="请输入您要搜索的内容"/>
      </div>
      <div class="nav">
        <div class="nav-right">
          <div class="menuDiv">
            <!--        <li :class="{active: activeName === 'Home'}"><router-link to="/">首页</router-link></li>-->
            <!--        <li :class="{active: activeName === 'NewsList'}"><router-link to="/NewsList">新闻动态</router-link></li>-->
            <!--        <li :class="{active: activeName === 'NoticeList'}"><router-link to="/noticeList">通知公告</router-link></li>-->
            <!--        <li :class="{active: activeName === 'Industry'}"><router-link to="/Industry">行业体系</router-link></li>-->
            <!--        <li :class="{active: activeName === 'PolicyList'}"><router-link to="/policyList">政策法规</router-link></li>-->
            <!--        <li :class="{active: activeName === 'StandardList'}"><router-link to="/standardList">技术规范</router-link></li>-->
            <!--        <li :class="{active: activeName === 'ExpertList'}"><router-link to="/expertList">专家支持</router-link></li>-->
            <!--        <li :class="{active: activeName === 'Business'}"><router-link to="/business">业务系统</router-link></li>-->
            <!--        <li :class="{active: activeName === 'DownList'}"><router-link to="/downList">资料下载</router-link></li>-->
            <ul>
              <li class="one">
                <router-link to="/">首页</router-link>
              </li>
              <li>
                <a>新闻通告<i class="select-icon"></i></a>
                <ul>
<!--                  <li>-->
<!--                    <router-link :to="{path:'/NewsList', query:{type:1}}">通知公告</router-link>-->
<!--                  </li>-->
                  <li>
                    <router-link :to="{path:'/NewsList', query:{type:2}}">要闻快讯</router-link>
                  </li>
                  <li>
                    <router-link :to="{path:'/NewsList', query:{type:3}}">行业动态</router-link>
                  </li>
                </ul>
              </li>
              <li class="sss">
                <a>行业体系<i class="select-icon"></i></a>
                <ul>
                  <li>
                    <router-link to="/Industry">省林检局</router-link>
                  </li>
                  <li>
                    <router-link to="/leadershipArea">领导专区</router-link>
                  </li>
                  <li>
                    <router-link to="/Industry">行业体系</router-link>
                  </li>
                  <li>
                    <router-link to="/expertList">专家支持</router-link>
                  </li>
                </ul>
              </li>
              <li>
                <a>林草保护<i class="select-icon"></i></a>
                <ul>
                  <li>
                    <a style="cursor: pointer" @click="goGrassDetail(1)">
                      林草概括
                    </a>
                    <!--                    <router-link-->
                    <!--                      :to="{path:'/textDetails', query:{url:'/yncy/grassProtect/list',category:1,titleOne:'林草保护',titleTwo:'林草概括'}}">-->
                    <!--                      林草概括-->
                    <!--                    </router-link>-->
                  </li>
                  <li>
                    <a style="cursor: pointer" @click="goGrassDetail(2)">
                      全省草地类
                    </a>
<!--                    <router-link-->
<!--                      :to="{path:'/textDetails', query:{url:'/yncy/grassProtect/list',category:2,titleOne:'林草保护',titleTwo:'全省草地类'}}">-->
<!--                      全省草地类-->
<!--                    </router-link>-->
                  </li>
                  <li>
                    <router-link to="/grass">林草分布</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{path:'/NewsLists', query:{url:'/yncy/grassProtect/list',category:3,titleOne:'林草保护',titleTwo:'监测预警'}}">
                      监测预警
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{path:'/NewsLists', query:{url:'/yncy/grassProtect/list',category:4,titleOne:'林草保护',titleTwo:'灾害防治'}}">
                      灾害防治
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{path:'/NewsLists', query:{url:'/yncy/grassProtect/list',category:5,titleOne:'林草保护',titleTwo:'检疫执法'}}">
                      检疫执法
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{path:'/NewsLists', query:{url:'/yncy/grassProtect/list',category:6,titleOne:'林草保护',titleTwo:'药剂药械'}}">
                      药剂药械
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <a>便民服务<i class="select-icon"></i></a>
                <ul>
                  <li>
                    <router-link to="/ApplicationGuide">办证指南</router-link>
                  </li>
                  <li>
                    <router-link to="/periodicalList">行业期刊</router-link>
                  </li>
                  <li>
                    <router-link to="/business">业务系统</router-link>
                  </li>
                  <li>
                    <router-link to="/downList">文件下载</router-link>
                  </li>
                  <li>
                    <router-link to="/video">行业视频</router-link>
                  </li>
                </ul>
              </li>
              <li>
                <a>专题专栏<i class="select-icon"></i></a>
                <ul>
                  <li>
                    <router-link to="/specialColumn">专题列表</router-link>
                  </li>
                </ul>
              </li>
<!--              <li>-->
<!--                <a>意见征集<i class="select-icon"></i></a>-->
<!--                <ul>-->
<!--&lt;!&ndash;                  <li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <router-link to="/messageBoard">建言献策</router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--                  <li>-->
<!--                    <router-link to="/opinion">意见征集</router-link>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
              <li>
                <a>政策法规<i class="select-icon"></i></a>
                <ul>
                  <li>
                    <router-link :to="{path:'/policyList', query:{type:1,titleOne:'行业法规'}}">行业法规</router-link>
                  </li>
                  <li>
                    <router-link :to="{path:'/policyList', query:{type:2,titleOne:'国家政策'}}">国家政策</router-link>
                  </li>
                  <li>
                    <router-link :to="{path:'/policyList', query:{type:3,titleOne:'法律法规'}}">法律法规</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <login v-if="visible" :visible="visible"></login>
  </div>
</template>

<script>
import {computed, defineComponent, ref, provide, onMounted, onDeactivated} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import login from './login'
import dayjs from "dayjs";
import bg1 from "../assets/bg1.png";
import bg2 from "../assets/bg2.png";
import bg3 from "../assets/bg3.png";
import bg4 from "../assets/bg4.png";
import {getAction} from "@/api";


export default defineComponent({
  name: 'Header',
  components: {login},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const timer = ref(null)
    let bgIndex = 0
    let bgOpa = 1;
    let bgTimer = ''
    const bgImgList = [bg1, bg2, bg3,bg4]
    const week = ref('')
    const visible = ref(false)
    const activeName = computed(() => {
      return route.name
    })
    const getWeek = () => {
      week.value = new Date().getDay()
      switch (week.value) {
        case 1:
          week.value = '星期一'
          break
        case 2:
          week.value = '星期二'
          break
        case 3:
          week.value = '星期三'
          break
        case 4:
          week.value = '星期四'
          break
        case 5:
          week.value = '星期五'
          break
        case 6:
          week.value = '星期六'
          break
        case 0:
          week.value = '星期日'
          break
      }
    }
    const time = setInterval(() => {
      timer.value = dayjs().format('YYYY-MM-DD HH:mm:ss')
    }, 1000)
    const keyWord = ref('')
    const search = () => {
      router.push({
        name: 'search',
        query: {
          keyWord: keyWord.value
        }
      })
    }
    const login = () => {
      visible.value = true
    }

    const userInfo = ref(null)
    const getUserInfo = () => {
      userInfo.value = JSON.parse(window.localStorage.getItem('yncy_userInfo'))
      console.log(userInfo.value)
    }
    const closeLogin = () => {
      visible.value = false
      getUserInfo()
    }
    const logOut = () => {
      window.localStorage.clear()
      location.reload()
    }
    const goGrassDetail = (type) => {
      getAction('/yncy/grassProtect/list', {category: type}).then(res => {
        if (res.success) {
          router.push({
            name: 'GrassDetial',
            query: {
              id: res.result.records[0].id
            }
          })
        }
      })
    }
    let hideInter = ''
    const hideBg = () => {
      hideInter = setInterval(() => {
        bgOpa = bgOpa - 0.02
        let bgDiv = document.getElementById("bg")
        bgDiv.style.opacity = bgOpa
        if (bgOpa <= 0) {
          clearInterval(hideInter)
          if (bgIndex == bgImgList.length) {
            bgIndex = 0
          }
          bgDiv.style.backgroundImage = `url(${bgImgList[bgIndex]})`
          bgIndex++
          showBg()
        }
      },10)
    }
    let showInter = ''
    const showBg = ()=>{
      showInter = setInterval(() => {
        bgOpa = bgOpa + 0.02;
        let bgDiv = document.getElementById("bg")
        bgDiv.style.opacity = bgOpa
        if (bgOpa >= 1) {
          clearInterval(showInter)
        }
      },10)
    }
    provide('closeLogin', closeLogin)
    onMounted(() => {
      getUserInfo()
      getWeek()
      bgTimer = setInterval(() => {
        hideBg()
      }, 5000)
    })
    onDeactivated(() => {
      if (bgTimer) {
        clearInterval(bgTimer)
      }
      if (hideInter) {
        clearInterval(hideInter)
      }
      if (showInter) {
        clearInterval(showInter)
      }
    })
    return {
      activeName,
      timer,
      bgIndex,
      bgImgList,
      week,
      keyWord,
      visible,
      userInfo,
      search,
      login,
      closeLogin,
      getUserInfo,
      logOut,
      showBg,
      hideBg,
      goGrassDetail
    }
  }
})
</script>
<style lang="scss">
.one a:hover {
  background-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.menuDiv {
  margin-left: 30px;
  position: relative;
  z-index: 9;
  display: inline-block;
}

/* 去掉a标签的下划线 */
.menuDiv a {
  text-decoration: none;
}

/* 设置ul和li的样式 */
.menuDiv ul, .menuDiv li {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
}

/* 设置二级菜单绝对定位，并隐藏 */
.menuDiv > ul > li > ul {
  position: absolute;
  display: none;
}

.menuDiv ul li ul li a {
  font-size: 14px;
  color: #FFFFFF;
}

/* 设置二级菜单的li的样式 */
.menuDiv > ul > li > ul > li {
  float: none;
}

/* 鼠标放在一级菜单上，显示二级菜单 */
.menuDiv > ul > li:hover ul {
  display: block;
}

/* 一级菜单 */
.menuDiv > ul > li > a {
  position: relative;
  width: 142px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 16px;

  .select-icon {
    position: absolute;
    top: 17px;
    right: 10px;
    display: block;
    background: url(../assets/arrowDown.png) no-repeat;
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}

/* 在一级菜单中，第一个不设置左边框 */
.menuDiv > ul > li:first-child > a {
  border-left: none;
}

/* 在一级菜单中，鼠标放上去的样式 */
.menuDiv > ul > li > a:hover {
  color: #FFFFFF;
  background-color: #00BB3D;
}

///* 二级菜单 */
.menuDiv > ul > li > ul > li {
  width: 142px;
  line-height: 42px;
  color: #fff;
  background-color: #00BB3D;
  text-align: center;
  //border: 1px solid #ccc;
  border-top: none;
  display: block;
}

//
///* 在二级菜单中，第一个设置顶边框 */
//.menuDiv > ul > li > ul > li:first-child > a {
//  border-top: 1px solid #ccc;
//}
//
/* 在二级菜单中，鼠标放上去的样式 */
//.menuDiv > ul > li > ul > li:hover .menuDiv >ul > li > a{
//  background-color: #fff !important;
//}
.menuDiv > ul > li > ul > li {
  position: relative;
}

.menuDiv > ul > li > ul > li::after {
  display: none;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.29);
  pointer-events: none;
  //opacity: 0.29
  //background: red(255, 255, 255, 0.5);
}

.menuDiv > ul > li > ul > li:hover::after {
  display: block;
}

/* 在二级菜单中，鼠标放上去的样式 */
//.menuDiv > ul > li > ul > li:hover{
//  background-color: #fff;
//}
//.menuDiv > ul > li > ul > li:hover a{
//  color:#000000
//}
.user {
  height: 32px;
  line-height: 32px;
  color: #FFFFFF !important;
}

.bg {
  position: relative;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  .bgImg{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../assets/bg4.png");
  }
  .search-box {
    margin-left: 30px;
    position: relative;
    display: flex;
  }
  .my_input {
    border-radius: 50px!important;;
    background: rgba(0,0,0,0.5)!important;;
    margin: 0px auto 40px auto;
    width: 260px!important;
    border: solid 1px white;
    padding: 4px 10px 6px 10px;
    color:white;
  }
  .top {
    margin: 0 auto;
    width: 1200px;
    height: 53px;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .top-left {
      width: 440px;
      display: flex;
      align-items: center;

      .time {
        width: 200px;
        font-size: 14px;
        color: #ffffff;
      }



      &-text {
        color: #ffffff;
        margin-bottom: 14px;
        text-align: right;

        span {
          padding: 0 5px;
        }
      }

      input {
        margin-left: 10px;
        width: 200px;
        height: 32px;
        padding: 10px 40px 10px 35px;
        border-radius: 20px;
        border: 0;
        background: rgba(0, 0, 0, 0.3);
        outline: 0;
        color: #fff !important;
      }

      i {
        position: absolute;
        left: 18px;
        top: 7px;
        display: block;
        background: url(../assets/search.png) no-repeat;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .top-right {
      display: flex;
      align-items: center;

      .login {
        width: 68px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #00BB3D;
        border-radius: 16px;
        font-weight: 400;
        color: #FFFFFF;
        font-size: 14px;
        margin-right: 32px;
        cursor: pointer;
      }

      .right-text {
        padding: 5px;
        color: #ffffff;
        font-size: 14px;
      }
    }
  }

  .nav {
    margin: 0 auto;
    width: 100%;
    z-index: 3;
    background: #57BE6B;
    //background: red;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-left {
      width: 270px;
      height: 71px;
    }
  }
}

.logo {
  width: 467px;
  margin-top: 44px;

  img {
    width: 100%;
  }
}

.w {
  overflow: hidden;
}

.nav {
  text-align: center;
  //background: #07C160;
  height: 40px;

}
</style>
